import React from 'react';
import { Modal, Typography, Button, Space } from 'antd';
import { RocketOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const UpgradeModal = ({ visible, onClose }) => (
  <Modal
    visible={visible}
    onCancel={onClose}
    footer={null}
    width={600}
  >
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Title level={4}>
        <RocketOutlined /> 業務遂行型AIでさらなる効率化へ！
      </Title>
      <Paragraph>
        フリープランのチケットを使い切りました。
        アップグレードしてあなたのビジネスを飛躍的に成長させませんか？
      </Paragraph>
      <Space direction="vertical">
        <Paragraph>
          <CheckCircleOutlined style={{ color: '#52c41a' }} /> <strong>生成記事数の増加</strong>：さらにSEO記事の展開を。
        </Paragraph>
        <Paragraph>
          <CheckCircleOutlined style={{ color: '#52c41a' }} /> <strong>便利な編集や共有機能</strong>：構成案の編集も可能に。
        </Paragraph>
        <Paragraph>
          <CheckCircleOutlined style={{ color: '#52c41a' }} /> <strong>予約時間の短縮</strong>：予約の待ち時間が最大50%短縮。
        </Paragraph>
      </Space>
      <Paragraph>
        AI時代のコンテンツSEO戦略で、
        ビジネスを加速させましょう！
      </Paragraph>
      <Button size="large">
        <Link to="/plan">今すぐプランをアップグレードする</Link>
      </Button>
    </Space>
  </Modal>
);

export default UpgradeModal;