import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space, Breadcrumb, Tag, Badge, Checkbox, Divider, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import UserDetail from './UserDetail';
import moment from 'moment-timezone';

const { Title, Text, Paragraph } = Typography;

const UserList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailVisible, setUserDetailVisible] = useState(false);
  const [showPendingOnly, setShowPendingOnly] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Token:', token);  // デバッグ出力
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const formattedData = response.data
        .filter(user => user.is_active)
        .map(user => ({
          ...user,
          registered_at: moment(user.registered_at).tz('Asia/Tokyo'),
          last_login_at: user.last_login_at ? moment(user.last_login_at).tz('Asia/Tokyo') : null,
          has_pending_custom_plan: user.has_pending_custom_plan,
          is_canceling: user.is_canceling,
        }));
      console.log('Formatted user data:', formattedData);
      console.log('Users with is_canceling=true:', formattedData.filter(user => user.is_canceling).length);
      setData(formattedData);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const showUserDetail = (userId) => {
    setSelectedUserId(userId);
    setUserDetailVisible(true);
  };

  const handleUserDetailCancel = () => {
    setSelectedUserId(null);
    setUserDetailVisible(false);
  };

  const handleUserUpdate = () => {
    fetchUsers();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });

  const planTags = {
    free: { color: 'blue', text: 'フリープラン' },
    standard: { color: 'green', text: 'スタンダードプラン' },
    premium: { color: 'gold', text: 'プレミアムプラン' },
    custom: { color: 'purple', text: 'カスタムプラン' },
  };

  const columns = [
    {
      title: '会社名/部署名',
      key: 'company',
      render: (_, record) => (
        <>
          <div>{record.company_name}</div>
          <div style={{ fontSize: '12px', color: '#888' }}>{record.department_name}</div>
        </>
      ),
      ...getColumnSearchProps('company_name'),
    },
    {
      title: '氏名',
      key: 'name',
      render: (_, record) => (
        <>
          <div>{record.last_name} {record.first_name}</div>
          <div style={{ fontSize: '12px', color: '#888' }}>
            {record.last_name_kana} {record.first_name_kana}
          </div>
        </>
      ),
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'プラン',
      dataIndex: 'plan',
      key: 'plan',
      render: (plan, record) => {
        const planInfo = plan ? planTags[plan.type] || planTags.custom : planTags.free;
        return (
          <>
            <Badge dot={record.has_pending_custom_plan}>
              <Tag color={planInfo.color}>
                {plan?.display_name || planInfo.text}
              </Tag>
            </Badge>
            {record.is_canceling && (
              <div style={{ fontSize: '11px', color: '#ff4d4f', marginTop: '4px' }}>自動キャンセル</div>
            )}
          </>
        );
      },
      filters: [
        { text: 'フリープラン', value: 'free' },
        { text: 'スタンダードプラン', value: 'standard' },
        { text: 'プレミアムプラン', value: 'premium' },
        { text: 'カスタムプラン', value: 'custom' },
        { text: '承認待ち', value: 'pending' },
        { text: 'キャンセル予約中', value: 'canceling' },
      ],
      onFilter: (value, record) => {
        if (value === 'pending') {
          return record.has_pending_custom_plan;
        }
        if (value === 'canceling') {
          return record.is_canceling;
        }
        return record.plan?.type === value || (!record.plan && value === 'free');
      },
    },
    {
      title: '次回更新日',
      dataIndex: 'next_renewal_date',
      key: 'next_renewal_date',
      render: (date) => date ? moment(date).format('YYYY/MM/DD') : '-',
      sorter: (a, b) => {
        if (!a.next_renewal_date) return 1;
        if (!b.next_renewal_date) return -1;
        return moment(a.next_renewal_date).diff(moment(b.next_renewal_date));
      },
    },
    {
      title: 'チケット枚数',
      dataIndex: 'ticket_count',
      key: 'ticket_count',
      sorter: (a, b) => a.ticket_count - b.ticket_count,
    },
    {
      title: '登録日',
      dataIndex: 'registered_at',
      key: 'registered_at',
      render: (text) => text.format('YYYY/MM/DD HH:mm'),
      sorter: (a, b) => a.registered_at.diff(b.registered_at),
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_at',
      key: 'last_login_at',
      render: (text) => text ? text.format('YYYY/MM/DD HH:mm') : '-',
      sorter: (a, b) => {
        if (!a.last_login_at) return 1;
        if (!b.last_login_at) return -1;
        return a.last_login_at.diff(b.last_login_at);
      },
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>管理者機能</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={1} className='gradient-text' style={{fontSize:'24px'}}>サブスクリプション管理</Title>
      <Divider style={{ margin: '24px 0' }} />
      <Space style={{ marginBottom: 16 }}>
        <Checkbox
          checked={showPendingOnly}
          onChange={(e) => setShowPendingOnly(e.target.checked)}
        >
          カスタムプラン承認待ちユーザーのみ表示
        </Checkbox>
      </Space>
      <Table 
        columns={columns} 
        dataSource={showPendingOnly ? data.filter(user => user.has_pending_custom_plan) : data} 
        rowKey="id" 
        onRow={(record) => ({
          onClick: () => showUserDetail(record.user_id),
          style: {cursor: 'pointer'}
        })}
      />
      <UserDetail
        visible={userDetailVisible}
        onCancel={handleUserDetailCancel}
        userId={selectedUserId}
        onUpdate={handleUserUpdate}
      />
    </>
  );
};

export default UserList;