import React, { useState, useEffect } from 'react';
import { Typography, Table, Tag, message, Spin, Divider } from 'antd';
import { api } from '../api';
import moment from 'moment-timezone';
import UserDetail from './UserManagement/UserDetail';

const { Title, Text } = Typography;

const AdminTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userDetailVisible, setUserDetailVisible] = useState(false);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const response = await api.get('/subscriptions/admin/transactions');
      setTransactions(response.data);
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
      if (error.response && error.response.status === 403) {
        message.error('アクセス権限がありません');
      } else {
        message.error('トランザクションの取得に失敗しました');
      }
    } finally {
      setLoading(false);
    }
  };

  const showUserDetail = (userId) => {
    setSelectedUserId(userId);
    setUserDetailVisible(true);
  };

  const handleUserDetailCancel = () => {
    setSelectedUserId(null);
    setUserDetailVisible(false);
  };

  const handleUserUpdate = () => {
    fetchTransactions();
  };

  const planNameMap = {
    'free': 'フリープラン',
    'standard': 'スタンダードプラン',
    'premium': 'プレミアムプラン',
    'custom': 'カスタムプラン'
  };

  const columns = [
    {
      title: 'ユーザー',
      dataIndex: 'user',
      key: 'user',
      render: (user) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => showUserDetail(user.user_id)}
          className="user-hover"
        >
          {`${user.last_name} ${user.first_name} (${user.email})`}
        </span>
      ),
    },
    {
      title: '日付',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => moment(date).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
    },
    {
      title: '金額（税込）',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `¥${amount.toLocaleString()}`,
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'succeeded' ? 'green' : 'red'}>
          {status === 'succeeded' ? '成功' : '失敗'}
        </Tag>
      ),
    },
    {
      title: '内容',
      dataIndex: 'plan_name',
      key: 'plan_name',
      render: (planName, record) => {
        const planType = planNameMap[planName] || planName;
        const transactionType = record.is_new ? '新規' : '継続';
        if (planName === 'custom') {
          return `${planType}（${transactionType}）`;
        } else if (planName === 'free') {
          return `${planType}（${transactionType}）`;
        } else {
          const paymentType = record.is_yearly ? '年払い' : '月払い';
          return `${planType}（${transactionType}／${paymentType}）`;
        }
      },
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div>
      <Text style={{ margin: '16px 0' }}>管理者機能</Text>
      <Title level={1} className='gradient-text' style={{fontSize:'24px'}}>トランザクション一覧</Title>
      <Divider style={{ margin: '24px 0' }} />
      <Table columns={columns} dataSource={transactions} rowKey="id" />
      <UserDetail
        visible={userDetailVisible}
        onCancel={handleUserDetailCancel}
        userId={selectedUserId}
        onUpdate={handleUserUpdate}
      />
      <style jsx>{`
        .user-hover:hover {
          color: #1890ff;
        }
      `}</style>
    </div>
  );
};

export default AdminTransactions;