import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate, Link } from 'react-router-dom';
import { Layout, Menu, message, Spin, Divider } from 'antd';
import {
  FormOutlined,
  DatabaseOutlined,
  UserOutlined,
  TeamOutlined,
  RocketOutlined,
  RobotOutlined,
  ClusterOutlined,
  BulbOutlined
} from '@ant-design/icons';
import SEOArticleDashboard from './components/SEOArticle/Dashboard';
import SEOArticleGenerator from './components/SEOArticle/Generate';
import SEOArticleList from './components/SEOArticle/ArticleList';
import SEOArticleDetail from './components/SEOArticle/ArticleDetail';
import SharedArticle from './components/SEOArticle/SharedArticle';
import UserSettings from './components/UserSettings';
import PlanSettings from './components/PlanSettings';
import Auth from './components/auth/Auth';
import RegisterProfile from './components/auth/RegisterProfile';
import ResetPassword from './components/auth/ResetPassword';
import RegisterEmail from './components/auth/RegisterEmail';
import { setAuthToken } from './api';
import AuthenticatedMenu from './components/AuthenticatedMenu';
import AuthenticatedRoute from './components/AuthenticatedRoute'
import { getUserRole, getUserProfile } from './auth';
import UserList from './components/UserManagement/UserList';
import AdminTransactions from './components/AdminTransactions';
import ConfirmEmail from './components/ConfirmEmail';
import RecommendedKeywords from './components/SEOArticle/RecommendedKeywords';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import SubscriptionManagement from './components/SubscriptionManagement';
import SubscriptionCanceled from './components/SubscriptionCanceled';
import UpgradeBanner from './components/UpgradeBanner';
import UpgradeModal from './components/UpgradeModal';

const { Header, Content, Sider } = Layout;

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('user');
  const [userProfile, setUserProfile] = useState(null);
  const [ticketCount, setTicketCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const getSelectedKey = (pathname) => {
    if (pathname === '/seo-article') return '/seo-article';
    if (pathname.startsWith('/seo-article/list')) return '/seo-article/list';
    if (pathname.startsWith('/seo-article/detail')) return '/seo-article/list';
    if (pathname.startsWith('/seo-article/observe_task')) return '/seo-article/observe_task';
    if (pathname.startsWith('/seo-article/keywords-recommend')) return '/seo-article/keywords-recommend';
    if (pathname.startsWith('/user-management')) return '/user-management';
    if (pathname.startsWith('/admin/transactions')) return '/admin/transactions';
    if (pathname.startsWith('/plan')) return '/plan';
    if (pathname.startsWith('/settings')) return '/settings';
    if (pathname.startsWith('/subscription-')) return '/subscription-management';
    return '';
  };

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if (token) {
        setAuthToken(token);
        try {
          const role = await getUserRole();
          setUserRole(role);
          setIsAuthenticated(true);

          try {
            const profile = await getUserProfile();
            setUserProfile(profile);
          } catch (error) {
            console.error('ユーザー情報の取得に失敗しました:', error);
            message.error('ユーザー情報の取得に失敗しました。');
          }
        } catch (error) {
          localStorage.removeItem('token');
          setIsAuthenticated(false);
          message.error('認証に失敗しました。再度ログインしてください。');
          if (!location.pathname.startsWith('/shared')) {
            navigate('/login', { replace: true });
          }
        }
      } else {
        setIsAuthenticated(false);
        if (!location.pathname.startsWith('/login') && 
            !location.pathname.startsWith('/register-email') && 
            !location.pathname.startsWith('/register-profile') && 
            !location.pathname.startsWith('/confirm-email') &&
            !location.pathname.startsWith('/reset-password') &&
            !location.pathname.startsWith('/shared')) {
          navigate('/login', { replace: true });
        }
      }
      setIsLoading(false);
    };

    checkAuth();
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (isAuthenticated && userProfile) {
      const ws = new WebSocket(`wss://${process.env.REACT_APP_API_URL}/ws/${userProfile.user_id}`);
      ws.onopen = () => {
        const token = localStorage.getItem('token');
        ws.send(token);
      };
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('WebSocket message:', data);
        setTicketCount(data.ticket_count);
        if (data.message) {
          message.success(data.message);
        }
      };
      return () => ws.close();
    }
  }, [isAuthenticated, userProfile]);

  useEffect(() => {
    if (isAuthenticated && userProfile) {
      const shouldShowUpgradePrompt = userProfile.plan.type === 'free' && userProfile.ticket_count === 0;
      if (shouldShowUpgradePrompt) {
        const lastPromptDate = localStorage.getItem('lastUpgradePromptDate');
        const today = new Date().toDateString();
        if (lastPromptDate !== today) {
          setShowUpgradeModal(true);
          localStorage.setItem('lastUpgradePromptDate', today);
        }
      }
    }
  }, [isAuthenticated, userProfile]);

  const handleCloseUpgradeModal = () => {
    setShowUpgradeModal(false);
  };

  const renderSidebar = () => {
    const selectedMenu = getSelectedKey(location.pathname);
    if (selectedMenu.startsWith('/seo-article')) {
      return (
        <Sider
          width={250}
          style={{
            height: '100vh',
            background: '#fff',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            paddingTop: '80px',
          }}
        >
          <div className='logo side'>magicss</div>
          <div style={{ fontSize: '14px', textAlign: 'center', color: '#999', fontWeight: 'bold' }}>
            <RobotOutlined style={{ marginRight: '4px' }} />
            モード：SEO記事生成
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedMenu]}
            style={{ height: '100%', borderRight: 0, paddingTop: '24px' }}
          >
            <Menu.Item key="/seo-article" icon={<FormOutlined />}>
              <Link to="/seo-article">新規生成</Link>
            </Menu.Item>
            <Menu.Item key="/seo-article/list" icon={<DatabaseOutlined />}>
              <Link to="/seo-article/list">生成結果一覧</Link>
            </Menu.Item>
            <Menu.Item key="/seo-article/keywords-recommend" icon={<BulbOutlined />}>
              <Link to="/seo-article/keywords-recommend">おすすめキーワード β</Link>
            </Menu.Item>

            {(userRole === 'master' || userRole === 'admin') && (
              <>
                <Divider style={{ margin: '16px 0' }} />
              
                <Menu.Item key="/seo-article/observe_task" icon={<ClusterOutlined />}>
                  <Link to="/seo-article/observe_task">タスク監視</Link>
                </Menu.Item>
              </>
            )}
          </Menu>
        </Sider>
      );
    } else if (selectedMenu === '/settings' || selectedMenu === '/plan' || selectedMenu === '/subscription-management') {
      return (
        <Sider
          width={250}
          style={{
            height: '100vh',
            background: '#fff',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            paddingTop: '80px',
          }}
        >
          <div className='logo side'>magicss</div>
          <div style={{ fontSize: '14px', textAlign: 'center', color: '#999', fontWeight: 'bold' }}>
            ユーザー設定
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedMenu]}
            style={{ height: '100%', borderRight: 0, paddingTop: '24px' }}
          >
            <Menu.Item key="/subscription-management" icon={<RocketOutlined />}>
              <Link to="/subscription-management">サブスクリプション管理</Link>
            </Menu.Item>
            <Menu.Item key="/plan" icon={<RocketOutlined />}>
              <Link to="/plan">プラン変更</Link>
            </Menu.Item>
            <Menu.Item key="/settings" icon={<UserOutlined />}>
              <Link to="/settings">登録情報変更</Link>
            </Menu.Item>
          </Menu>
        </Sider>
      );
    } else if (
      (selectedMenu === '/user-management' || selectedMenu === '/admin/transactions') &&
      (userRole === 'admin' || userRole === 'master')
    ) {
      return (
        <Sider
          width={250}
          style={{
            height: '100vh',
            background: '#fff',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            paddingTop: '80px',
          }}
        >
          <div className='logo side'>magicss</div>
          <div style={{ fontSize: '14px', textAlign: 'center', color: '#999', fontWeight: 'bold' }}>
            管理者メニュー
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedMenu]}
            style={{ height: '100%', borderRight: 0, paddingTop: '24px' }}
          >
            <Menu.Item key="/user-management" icon={<TeamOutlined />}>
              <Link to="/user-management">ユーザー管理</Link>
            </Menu.Item>
            <Menu.Item key="/admin/transactions" icon={<DatabaseOutlined />}>
              <Link to="/admin/transactions">トランザクション一覧</Link>
            </Menu.Item>
          </Menu>
        </Sider>
      );
    }
  };

  const renderAuthenticatedRoutes = () => (
    <Layout hasSider style={{ minHeight: '100vh' }}>
      {renderSidebar()}
      <Layout style={{ marginLeft: 250 }}>
        {userProfile && (
          <Header
            style={{
              background: '#fff',
              padding: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <AuthenticatedMenu userProfile={userProfile} userRole={userRole} ticketCount={ticketCount} />
          </Header>
        )}
        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: '24px 24px 0',
            minHeight: 280,
          }}
        >
          {userProfile && userProfile.plan.type === 'free' && userProfile.ticket_count === 0 && (
            <UpgradeBanner />
          )}
          <Routes>
            <Route path="/seo-article" element={<SEOArticleGenerator />} />
            <Route path="/seo-article/observe_task" element={<SEOArticleDashboard />} />
            <Route path="/seo-article/list" element={<SEOArticleList userRole={userRole} />} />
            <Route path="/seo-article/list/:userId" element={<SEOArticleList userRole={userRole} />} />
            <Route path="/seo-article/detail/:uuid" element={<SEOArticleDetail />} />
            <Route path="/seo-article/keywords-recommend" element={<RecommendedKeywords />} />
            <Route path="/settings" element={<UserSettings />} />
            <Route path="/plan" element={<PlanSettings />} />
            <Route 
              path="/subscription-success" 
              element={
                <AuthenticatedRoute>
                  <SubscriptionSuccess />
                </AuthenticatedRoute>
              } 
            />
            <Route 
              path="/subscription-management" 
              element={
                <AuthenticatedRoute>
                  <SubscriptionManagement />
                </AuthenticatedRoute>
              } 
            />
            <Route
              path="/user-management"
              element={
                userRole === 'admin' || userRole === 'master' ? (
                  <UserList />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/admin/transactions"
              element={
                userRole === 'admin' || userRole === 'master' ? (
                  <AdminTransactions />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route path="/subscription-canceled" element={<SubscriptionCanceled />} />
            <Route path="/settings/confirm-email/:token" element={<ConfirmEmail />} />
            <Route path="*" element={<Navigate to="/seo-article" />} />
          </Routes>
          <UpgradeModal visible={showUpgradeModal} onClose={handleCloseUpgradeModal} />
        </Content>
      </Layout>
    </Layout>
  );

  const renderUnauthenticatedRoutes = () => (
    <Layout className="gate_wrap">
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/register-email" element={<RegisterEmail />} />
        <Route path="/register-profile/:token" element={<RegisterProfile />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Layout>
  );

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Routes>
          <Route path="/shared/:uuid" element={<SharedArticle />} />
          {isAuthenticated ? (
            <Route path="/*" element={renderAuthenticatedRoutes()} />
          ) : (
            <Route path="/*" element={renderUnauthenticatedRoutes()} />
          )}
        </Routes>
      )}
    </>
  );
};

export default App;
