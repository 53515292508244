import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Space, Divider, Button, Table, Tag, Modal, message, Spin, Alert } from 'antd';
import { FireOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../auth';
import { api } from '../api';
import moment from 'moment-timezone';

const { Title, Text, Paragraph } = Typography;

const SubscriptionManagement = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const profile = await getUserProfile();
      console.log("User profile in SubscriptionManagement:", profile);
      const [subscriptionResponse, transactionsResponse] = await Promise.all([
        api.get('/subscriptions/current-subscription'),
        api.get('/subscriptions/transactions')
      ]);
      setUserProfile(profile);
      setCurrentSubscription(subscriptionResponse.data);
      setTransactions(transactionsResponse.data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      message.error('データの取得に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await api.post('/subscriptions/cancel-subscription');
      message.success('サブスクリプションをキャンセルしました');
      fetchData();
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
      message.error('サブスクリプションのキャンセルに失敗しました');
    }
    setCancelModalVisible(false);
  };

  const handleChangePaymentMethod = async () => {
    try {
      const response = await api.post('/subscriptions/create-portal-session');
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Failed to create portal session:', error);
      message.error('決済方法の変更に失敗しました。管理者にお問い合わせください。');
    }
  };

  const planTags = {
    free: { color: 'blue', text: 'フリープラン' },
    standard: { color: 'green', text: 'スタンダードプラン' },
    premium: { color: 'gold', text: 'プレミアムプラン' },
    custom: { color: 'purple', text: 'カスタムプラン' },
  };

  const planNameMap = {
    'free': 'フリープラン',
    'standard': 'スタンダードプラン',
    'premium': 'プレミアムプラン',
    'custom': 'カスタムプラン'
  };

  const getNextUpdateDate = () => {
    if (!currentSubscription || !userProfile) return 'N/A';
  
    const now = moment();
    const lastUpdate = moment(userProfile.last_ticket_update_at);
    const periodEnd = moment(currentSubscription.current_period_end);
    
    if (currentSubscription.plan.type === 'free') {
      // フリープランの場合
      const nextUpdate = lastUpdate.add(30, 'days');
      return nextUpdate.isAfter(now) ? nextUpdate.format('YYYY年MM月DD日') : '更新予定';
    } else if (currentSubscription.is_yearly || (currentSubscription.plan.type === 'custom' && currentSubscription.custom_data?.is_monthly_update)) {
      // 年払いまたはカスタムプランの月毎付与の場合
      const daysUntilPeriodEnd = periodEnd.diff(now, 'days');
      if (daysUntilPeriodEnd <= 40) {
        return periodEnd.format('YYYY年MM月DD日');
      } else {
        const nextUpdate = lastUpdate.add(30, 'days');
        return nextUpdate.isAfter(now) ? nextUpdate.format('YYYY年MM月DD日') : '更新予定';
      }
    } else if (currentSubscription.plan.type === 'custom' && !currentSubscription.custom_data?.is_monthly_update) {
      // カスタムプランの一括付与の場合
      return null;
    } else {
      // 月払いの場合
      return periodEnd.format('YYYY年MM月DD日');
    }
  };

  const CancelSubscriptionModal = ({ visible, onCancel, onConfirm, subscription }) => {
    const isYearly = subscription?.is_yearly;
    const nextRenewalDate = subscription ? moment(subscription.current_period_end).format('YYYY年MM月DD日') : 'N/A';
  
    return (
      <Modal
        visible={visible}
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            <ExclamationCircleOutlined style={{ color: '#faad14', marginRight: 8 }} />
            サブスクリプションのキャンセル
          </Title>
        }
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            戻る
          </Button>,
          <Button key="submit" type="primary" danger onClick={onConfirm}>
            キャンセルを確定
          </Button>,
        ]}
        width={600}
      >
        <div style={{ marginTop: '20px' }}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Alert
              message="キャンセルの影響"
              description={
                <ul style={{ paddingLeft: '20px', marginBottom: 0 }}>
                  <li>現在のプランは{nextRenewalDate}まで利用可能です。</li>
                  <li>{nextRenewalDate}以降は自動的にフリープランに変更されます。</li>
                  <li>フリープランでは機能が制限されます。</li>
                </ul>
              }
              type="warning"
              showIcon
            />
  
            {isYearly && (
              <Alert
                message="年払いプランに関する重要な注意"
                description={
                  <ul style={{ paddingLeft: '20px', marginBottom: 0 }}>
                    <li>
                      <strong>未使用のチケットについて：</strong> {nextRenewalDate}時点で使用されなかったチケットは無効となります。お早めにご利用ください。
                    </li>
                    <li>
                      <strong>返金について：</strong> 年払いプランのキャンセルに伴う返金は行っておりません。
                    </li>
                  </ul>
                }
                type="error"
                showIcon
                icon={<WarningOutlined />}
              />
            )}
  
            <div style={{ marginTop: '10px' }}>
              <Paragraph>
                <Text strong>キャンセル後の動作：</Text>
              </Paragraph>
              <ul style={{ marginTop: '5px', marginBottom: '10px' }}>
                <li>現在の保有チケットは{nextRenewalDate}まで使用できます。</li>
                <li>{nextRenewalDate}以降、チケットは更新されなくなります。</li>
                <li>フリープランの制限内で引き続きサービスをご利用いただけます。</li>
              </ul>
            </div>
  
            <Divider style={{ margin: '10px 0' }} />
  
            <Paragraph>
              <Text type="secondary">
                キャンセルを確定する前に、もう一度ご確認ください。キャンセル後も{nextRenewalDate}までは現在のプランをご利用いただけます。
              </Text>
            </Paragraph>
          </Space>
        </div>
      </Modal>
    );
  };

  const columns = [
    {
      title: '日付',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => moment(date).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm'),
    },
    {
      title: '金額（税込）',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `¥${amount.toLocaleString()}`,
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'succeeded' ? 'green' : 'red'}>
          {status === 'succeeded' ? '成功' : '失敗'}
        </Tag>
      ),
    },
    {
      title: '内容',
      dataIndex: 'plan_name',
      key: 'plan_name',
      render: (planName, record) => {
        const planType = planNameMap[planName] || planName;
        const transactionType = record.is_new ? '新規' : '継続';
        if (planName === 'custom') {
          return `${planType}（${transactionType}）`;
        } else if (planName === 'free') {
          return `${planType}（${transactionType}）`;
        } else {
          const paymentType = record.is_yearly ? '年払い' : '月払い';
          return `${planType}（${transactionType}／${paymentType}）`;
        }
      },
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div>
      <Text style={{ margin: '16px 0' }}>ユーザー設定</Text>
      <Title level={1} className='gradient-text' style={{fontSize:'24px'}}>サブスクリプション管理</Title>
      <Divider style={{ margin: '24px 0' }} />
      
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card title="現在のプラン">
            {currentSubscription && (
              <>
                <Tag color={planTags[currentSubscription.plan.type]?.color || 'default'}>
                  {planTags[currentSubscription.plan.type]?.text}
                  {currentSubscription.plan.type !== 'free' && `（${currentSubscription.is_yearly ? '年払い' : '月払い'}）`}
                </Tag>
                <Divider />
                {currentSubscription.plan.type !== 'free' && (
                  <>
                    <Text>
                      {currentSubscription.plan.type === 'custom' ? '有効期限' : '次回更新日'}：
                      {moment(currentSubscription.current_period_end).format('YYYY年MM月DD日')}
                    </Text>
                    {currentSubscription.status === 'canceling' && (
                      <Text type="warning" style={{ marginLeft: '8px' }}>（自動キャンセル）</Text>
                    )}
                    {currentSubscription.status === 'active' && (
                      <Button 
                        onClick={() => setCancelModalVisible(true)} 
                        style={{marginLeft: '24px'}}
                        size='small'
                      >
                        キャンセルする
                      </Button>
                    )}
                  </>
                )}
                {currentSubscription.plan.type === 'free' && (
                  <Text>フリープランには有効期限はありません。</Text>
                )}
              </>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="チケット">
            {userProfile && currentSubscription && (
              <>
                <Text>
                  チケット残り：<FireOutlined style={{color: '#0072ff'}} /> 
                  <Text style={{color:'#0072ff',margin:'0 4px 0 4px',fontSize:'16px'}}>{userProfile.ticket_count}</Text>枚
                </Text>
                <Divider />
                {getNextUpdateDate() && (
                  <Text>
                    次回は{getNextUpdateDate()}に
                    <FireOutlined style={{color: '#0072ff'}} /> {userProfile.plan?.monthly_tickets || 0}枚に更新
                  </Text>
                )}
              </>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="決済方法">
            {userProfile && (
              <>
                <div style={{
                  background: 'linear-gradient(45deg, #e5f3ff, #ffeadc)',
                  borderRadius: '10px',
                  padding: '10px',
                  textAlign: 'center',
                  marginBottom: '16px'
                }}>
                  <Text style={{ fontSize: '14px', marginRight: '16px', color:'#647a7a' }}>
                    {userProfile.brand ? `${userProfile.brand.toUpperCase()}` : 'カード'}
                  </Text>
                  <Text style={{ fontSize: '18px', letterSpacing: '2px', color:'#647a7a' }}>
                    {userProfile.last4 ? `**** **** **** ${userProfile.last4}` : '未登録'}
                  </Text>
                </div>
                <Button onClick={handleChangePaymentMethod} block>
                  決済方法を変更する
                </Button>
              </>
            )}
          </Card>
        </Col>
      </Row>

      <Title level={3} style={{ margin: '32px 0 16px', fontSize: '18px' }}>決済履歴</Title>
      <Table columns={columns} dataSource={transactions} rowKey="id" />

      <CancelSubscriptionModal
        visible={cancelModalVisible}
        onCancel={() => setCancelModalVisible(false)}
        onConfirm={handleCancelSubscription}
        subscription={currentSubscription}
      />
    </div>
  );
};

export default SubscriptionManagement;